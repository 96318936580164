.search-options {
  margin-bottom: 20px;
}

.search-options span.ms-Checkbox-text {
  font-weight: bold;
  font-size: 16px;
}

.search-options .form-group {
  margin-right: 10px;
}

.search-options select, .search-options #prerelease {
  margin-left: 10px;
}

.search-dropdown {
  margin-left: 5px;
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.search-result {
  padding-top: 15px;
  padding-bottom: 15px;
}

.search-result .package-title {
  font-size: 24px;
  font-weight: 300;
  line-height: .9;
  margin-right: 5px;
}

.search-result .info {
  padding-left: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: -5px;
  line-height: 20px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-result .info span:not(:first-child){
  padding-left: 10px;
}

.search-result .info li {
  display: inline;
}

.search-result .info li + li {
  padding-left: 10px;
  margin-left: 10px;
  border-color: #dbdbdb;
  border-width: 1px;
  border-left-style: solid;
}

.search-result .ms-Icon {
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.search-result .tags {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-bottom-4rem {
  margin-bottom: 10rem;
}
