#navbar ul {
  margin-left: -15px;
}

.navbar-inverse {
  background-color: #004880;
  border-color: #002b4d;
}

.navbar {
  border-radius: 0;
  min-height: 210px;
}

.main-container {
  min-height: calc(100vh + (-295px));
}

@media (min-width: 768px) {
  .navbar {
    min-height: 110px;
  }

  .main-container {
    min-height: calc(100vh + (-215px));
  }
}

.navbar-inverse .navbar-nav > li > a {
  font-weight: 100;
  color: #e3ebf1;
}

.navbar-nav > li > a {
  line-height: 22px;
}


.navbar-inverse .navbar-nav a.active {
  color: #e3ebf1;
}

.navbar-inverse .navbar-nav a.active,
.navbar-inverse .navbar-nav a.active:hover,
.navbar-inverse .navbar-nav a.active:focus {
  background-color: transparent;
}

.navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
}

.navbar-inverse .navbar-nav a.active span {
  padding: 5px 0;
  border-bottom: 1px solid #fff;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.search-container {
  padding-top: 16px;
  padding-bottom: 16px;
}

.search-container .form-control {
  border-radius: 0;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  color: #337ab7;
}

.link-button:hover,
.link-button:focus {
  outline: none;
  text-decoration: underline;
  color: #23527c;
}

.put-left {
  align-items: start;
  justify-content: left;
}

.dy-5 {
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

ul.navbar-nav > li > a.active {
  font-weight: 600;
}

.navbar-logo {
  padding: 0;
  margin: 8px 17px 0 13px;
}

.footer {
  background-color: #002440;
  color: #e3ebf1;
  font-size: 1em;
  display: block;
  margin-top: 20px;
  min-height: 65px;
}

.footer-content {
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-content > a {
  color: #e3ebf1;
}

.middle-hidden {
  display: none;
}

@media (max-width: 767.9px) {
  .footer-content {
    text-align: center;
  }

  .footer {
    min-height: 45px;
  }

  .footer-content {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .nav > li > a {
    padding: 10px 15px 0;
  }

  .navbar-inverse .navbar-nav a.active span {
    padding: 0;
    border-bottom: none;
  }

  .search-container {
    padding-top: 10px;
  }

  .middle-shown {
    display: none;
  }

  .middle-hidden {
    display: inline;
  }
}
